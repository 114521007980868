import React from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

const skillContent = [
  {
    name: 'ASP.NET (Webforms, MVC, .Net Core)',
    numberPercent: '90',
    startCount: '0',
    endCount: '90',
  },
  {
    name: 'C#',
    numberPercent: '91',
    startCount: '0',
    endCount: '91',
  },
  {
    name: 'Angular',
    numberPercent: '78',
    startCount: '0',
    endCount: '78',
  },
  {
    name: 'React',
    numberPercent: '50',
    startCount: '0',
    endCount: '50',
  },
  {
    name: 'Node.js',
    numberPercent: '80',
    startCount: '0',
    endCount: '80',
  },
  {
    name: 'JavaScript & jQuery',
    numberPercent: '90',
    startCount: '0',
    endCount: '90',
  },
  {
    name: 'Microsoft SQL Server',
    numberPercent: '85',
    startCount: '0',
    endCount: '85',
  },
  {
    name: 'Mongo DB',
    numberPercent: '75',
    startCount: '0',
    endCount: '75',
  },
  {
    name: 'HTML5',
    numberPercent: '92',
    startCount: '0',
    endCount: '92',
  },
  {
    name: 'CSS',
    numberPercent: '95',
    startCount: '0',
    endCount: '95',
  },
]

const Skills = () => {
  const [focus, setFocus] = React.useState(false)
  return (
    <>
      <div className="skill-wrapper">
        {skillContent.map((skill, i) => (
          <div className="skill-lt" key={i}>
            <h6>{skill.name}</h6>
            <span className="count-inner">
              <CountUp
                start={focus ? skill.startCount : null}
                end={skill.endCount}
                duration={1}
                redraw={true}
              >
                {({ countUpRef }) => (
                  <VisibilitySensor
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setFocus(true)
                      }
                    }}
                  >
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              %
            </span>
            <div className="skill-bar">
              <div
                className="skill-bar-in"
                style={{ width: skill.numberPercent + '%' }}
              ></div>
            </div>
          </div>
          // End skill-lt
        ))}
      </div>
    </>
  )
}

export default Skills
