import React from 'react'
import TextLoop from 'react-text-loop'
import ImgNextGen from '../image/ImgNextGen'

const conctInfo = {
  phone: '(+91) 787 416 0552',
  email: ' mayursasp.net@gmail.com',
}

const sliderContent = {
  name: 'Mayur Siddhapura',
  designation: 'Full Stack Developer',
  description: `With a focus on creating stylish, modern websites, web services and online stores, I specialize in designing and developing services for customers of all sizes.`,
  btnText: ' Donwload CV',
}

const Slider = () => {
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href="tel:+91 787-416-0552">{conctInfo.phone}</a>
            <a href="mailto:mayursasp.net@gmail.com">{conctInfo.email}</a>
          </div>
          {/* <div className="hb-lang">
            <ul className="nav">
              <li className="active">
                <a href="#">EN</a>
              </li>
              <li>
                <a href="#">FR</a>
              </li>
            </ul>
          </div> */}
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  Hello, My name is
                </h6>
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.name}
                </h1>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <TextLoop>
                    <p className="loop-text lead">Full Stack Developer</p>
                    <p className="loop-text lead"> Web Designer / Developer</p>
                    <p className="loop-text lead"> Programmer</p>
                    <p className="loop-text lead"> Front-End Developer</p>
                    <p className="loop-text lead"> Back-End Developer</p>
                  </TextLoop>
                </div>

                <p
                  className="desc"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  {sliderContent.description}
                </p>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a
                    className="px-btn px-btn-white"
                    href="cv-mayur-siddhapura.pdf"
                    download
                  >
                    {sliderContent.btnText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}

        <div
          className="hb-me"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + 'img/slider/home-banner.jpg'
            })`,
          }}
        ></div>
      </section>

      {/* End Home Banner  */}
    </>
  )
}

export default Slider
