import React from 'react'

const Map = () => {
  return (
    <>
      <div className="google-map">
        <div className="embed-responsive embed-responsive-21by9">
          <iframe
            title="Google Map"
            className="embed-responsive-item"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.911119493058!2d72.66188981428178!3d22.990295623304245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e87d2dd8c4939%3A0x8dd9ca2c0637335a!2sBlue%20Iris!5e0!3m2!1sen!2sin!4v1637809549361!5m2!1sen!2sin"
          ></iframe>
        </div>
      </div>
      {/* End google-map */}
    </>
  )
}

export default Map
