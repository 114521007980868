import React from 'react'
import { useForm } from 'react-hook-form'

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = React.useState(
    false,
  )

  const onSubmit = async (data, e) => {
    e.target.reset()

    let response = await fetch('https://mayursaspdotnet.com/mail.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    })

    let result = await response.json()

    // console.log('Message submited: ' + JSON.stringify(data))
    // console.log('Result: ' + result.status)

    setIsSuccessfullySubmitted(result.status)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control theme-light"
                placeholder="Full name"
                {...register('name', { required: true })}
              />
              {errors.name && errors.name.type === 'required' && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}
          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="email"
                className="form-control theme-light"
                placeholder="Email address"
                {...register(
                  'email',
                  {
                    required: 'Email is Required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  },
                  { required: true },
                )}
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}
          <div className="col-12">
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control theme-light"
                placeholder="Subject"
                {...register('subject', { required: true })}
              />
              {errors.subject && (
                <span className="invalid-feedback">Subject is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}
          <div className="col-12">
            <div className="form-group mb-3">
              <textarea
                rows="4"
                className="form-control theme-light"
                placeholder="Type comment"
                // {...register('comment', { required: true })}
              ></textarea>
              {/* {errors.comment && (
                <span className="invalid-feedback">Comment is required.</span>
              )} */}
            </div>
          </div>
          {/* End .col-12 */}
          <div className="col-12">
            <div className="btn-bar">
              <button className="px-btn px-btn-white">Send Message</button>
            </div>
          </div>

          {isSuccessfullySubmitted && (
            <div className="col-12 mt-3">
              <div class="alert alert-success">
                <p class="text-black">
                  <strong>Thanks for being awesome!</strong>
                </p>

                <p class="text-black">
                  I have received your message and would like to thank you for
                  writing to me. If your enquiry is urgent, please use the cell
                  number listed above to talk to me directly.
                </p>

                <p class="text-black">
                  Otherwise, I will reply by email as soon as possible.
                </p>

                <p class="text-black">Talk to you soon, M@yuR$</p>
              </div>
            </div>
          )}
          {/* End .col-12 */}
        </div>
      </form>
    </>
  )
}

export default Contact
