import React from 'react'
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaSkype,
} from 'react-icons/fa'

const SocialShare = [
  { Social: <FaFacebookF />, link: 'https://www.facebook.com/MayurSidd29/' },
  { Social: <FaWhatsapp />, link: 'https://wa.link/2d2nlw/' },
  { Social: <FaSkype />, link: 'skype:mayur_siddhapura?chat/' },
  {
    Social: <FaLinkedinIn />,
    link: 'https://www.linkedin.com/in/mrsiddhapura/',
  },
  {
    Social: <FaInstagram />,
    link: 'https://www.instagram.com/mayur_siddhapura/',
  },
]

const Footer = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 my-2">
          <div className="nav justify-content-center justify-content-md-start">
            {SocialShare.map((val, i) => (
              <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                {val.Social}
              </a>
            ))}
          </div>
          {/* End .nav */}
        </div>
        {/* End .col */}

        <div className="col-md-6 my-2 text-center text-md-end">
          {/* <p>
            © {new Date().getFullYear()} copyright{' '}
            <a
              href="https://themeforest.net/user/ib-themes/portfolio"
              target="_blank"
              rel="noreferrer"
            >
              ib-themes
            </a>{' '}
            all right reserved
          </p> */}
        </div>
        {/* End .col */}
      </div>
      {/* End .row */}
    </>
  )
}

export default Footer
