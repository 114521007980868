import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ImgNextGen from '../image/ImgNextGen'

export default function SimpleSlider() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const TestimonilContent = [
    {
      imageName: 'gary-engelbrecht',
      desc: `Mayur has been working with us and assisting us in developing our program (BCMTrac) over the last 4 or 5 years already. His work ethic and speed are factors which we truly appreciate. His understanding of the coding used and the problem solving has been excellent to date and real value for money. I have nothing but respect for Mayur. I can highly recommend Mayur. He is hard working and puts in plenty of hours to get the work completed.`,
      reviewerName: 'Gary Engelbrecht',
      designation: 'Managing Director at BCMTrac (Pty) Ltd',
      delayAnimation: '',
    },
    {
      imageName: 'nirav-joshi',
      desc: `Mayur is a dedicated and conscientious web developer who made valuable contributions to my projects in terms of development and functional, taking on aspects of this work from inception to reality in a reliable and professional manner. He consistently delivered projects to the client’s specifications within the required time frame and is someone with excellent interpersonal skills.`,
      reviewerName: 'Nirav Joshi',
      designation:
        'Founder and Director at Devstree IT Services Private Limited',
      delayAnimation: '200',
    },
  ]

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div
            key={i}
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="testimonial-01 media">
              <div className="avatar">
                <ImgNextGen
                  srcWebp={`img/testimonial/${val.imageName}.webp`}
                  srcJrx={`img/testimonial/${val.imageName}.jxr`}
                  srcJp2={`img/testimonial/${val.imageName}.jp2`}
                  fallback={`img/testimonial/${val.imageName}.jpg`}
                  alt={`${val.reviewerName}`}
                />
              </div>
              <div className="media-body">
                <p>{val.desc}</p>
                <h6>{val.reviewerName}</h6>
                <span>{val.designation}</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
