import React from 'react'
import Skills from '../skills/Skills'

const resumeContent = [
  {
    jobPosition: `Team Leader | Full Stack Developer`,
    jobType: `Contractor | Remote`,
    jobDuration: `May 2019 - Present`,
    timeDuraton: `Full Time`,
    compnayName: 'Self Employed',
    jobDescription: `Analyze requirements and provide clients with most realistic and effective solutions and supervise a team of 4 developers with my own involvement. Worked on requirements analysis, design, coding, testing, and deployment. Monitor the development of the client's requirements and assign tasks to the other team members.`,
    delayAnimation: '',
  },
  {
    jobPosition: `Sr. ASP.NET Developer`,
    jobType: `Employee | In-house`,
    jobDuration: `Jul 2015 - Apr 2019`,
    timeDuraton: `Full Time`,
    compnayName: 'Siya Infotech Solutions Pvt. Ltd.',
    jobDescription: `Provide suggestions and solutions to any new requirements and/or problems as requested by the client. Conduct independent research and analysis of the client's requests. Work on feasible and effective solutions based on requirements. Build, test, and deploy projects on the IIS server. Help other teammates resolve complex issues simultaneously on other projects.`,
    delayAnimation: '100',
  },
  {
    jobPosition: `Jr. ASP.NET Developer`,
    jobType: `Employee | In-house`,
    jobDuration: `Jan 2015 - Jun 2015`,
    timeDuraton: `Full Time`,
    compnayName: 'VIPSha Inc.',
    jobDescription: `Consultation with project manager regarding project requirements; development, design, and testing on own; implementation on server; support staff in other projects while dealing with issue.`,
    delayAnimation: '200',
  },
]

const educatonContent = [
  {
    passingYear: '2010-2013',
    degreeTitle: 'Bachelor of Eng. in Information Technology',
    instituteName:
      'Aurum Institute of Engineering & Technology AKA Takshashila (TCET)',
  },
  {
    passingYear: '2007-2010',
    degreeTitle: 'Diploma in Information Technology',
    instituteName: 'Government Polytechnic Ahmedabad 617',
  },
]

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience.</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div
                className="resume-row"
                key={i}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay={val.delayAnimation}
              >
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h6>{val.jobPosition}</h6>
                      <label>{val.jobType}</label>
                      <p>{val.jobDuration}</p>
                      <div className="rb-time">{val.timeDuraton}</div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.compnayName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + 'img/border-dark.png'
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Education & Skills</h3>{' '}
          </div>

          <div className="row align-items-center">
            <div
              className="col-lg-4 m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <span>{val.passingYear}</span>
                    <h6>{val.degreeTitle} </h6>
                    <p>{val.instituteName}</p>{' '}
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div
              className="col-lg-7 ml-auto m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <Skills />
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  )
}

export default Resume
