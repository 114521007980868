import React from 'react'
import {
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
  FaLinkedinIn,
  FaSkype,
} from 'react-icons/fa'

const SocialShare = [
  { Social: <FaFacebookF />, link: 'https://www.facebook.com/MayurSidd29/' },
  { Social: <FaWhatsapp />, link: 'https://wa.link/2d2nlw/' },
  {
    Social: <FaSkype />,
    link: 'skype:mayur_siddhapura?chat/',
  },
  {
    Social: <FaLinkedinIn />,
    link: 'https://www.linkedin.com/in/mrsiddhapura/',
  },
  {
    Social: <FaInstagram />,
    link: 'https://www.instagram.com/mayur_siddhapura/',
  },
]

const Social = () => {
  return (
    <div className="nav social-icons justify-content-center">
      {SocialShare.map((val, i) => (
        <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
          {val.Social}
        </a>
      ))}
    </div>
  )
}

export default Social
