import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from 'simple-react-lightbox'
import { FiLink } from 'react-icons/fi'
import Masonry from 'react-masonry-css'
import ImgNextGen from '../image/ImgNextGen'

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
}

const tabList = ['All', 'Web Applications', 'Designing', 'Consoles & Utilities']
const AllPortfolioContent = [
  {
    img: 'birdmorning',
    title: 'Bird Morning Solutions',
    subTitle: 'Company Website',
    alterText: 'Designing',
    delayAnimation: '',
    portfolioLink: 'https://www.birdmorning.com/',
  },
  {
    img: 'harmonee',
    title: 'Harmonee',
    subTitle:
      'Simple app uses the power of many people to help local businesses and nonprofits build strong communities',
    alterText: 'Web Application',
    delayAnimation: '',
    portfolioLink: 'https://www.harmonee.org/',
  },
  {
    img: 'chat2cars',
    title: 'Chat2Cars | Buy and Sell Cars Online',
    subTitle: 'Solution for Online Car Marketplace',
    alterText: 'Web Application',
    delayAnimation: '',
    portfolioLink: 'https://chat2cars.co.za/',
  },
  {
    img: 'cps-home',
    title: 'CPS (Cloud Property Solutions)',
    subTitle: 'Solution for Estate and Managing Agencies',
    alterText: 'Web Application',
    delayAnimation: '100',
    portfolioLink: 'https://www.cloudpropertysolutions.co.za/',
  },
  {
    img: 'dia-Dashboard',
    title: 'Digital Internal Audit (Audit Management)',
    subTitle: 'Solution for accounting departments to manage Audits procedure',
    alterText: 'Web Application',
    delayAnimation: '200',
    portfolioLink: '',
  },
  {
    img: 'bcmtrac-05-home',
    title: 'Body Corporate / H.O.A. Management',
    subTitle:
      'Solution which can meet the requirements from single users to small, medium and large organizations',
    alterText: 'Web Application',
    delayAnimation: '0',
    portfolioLink: '',
  },
  {
    img: 'vedant',
    title: 'Vedant Groups Of Company',
    subTitle: 'Builder Scheme Website',
    alterText: 'Static Responsive HTML Website',
    delayAnimation: '100',
    portfolioLink: 'http://www.vedantgroups.com/',
  },
  {
    img: 'db-backup',
    title: 'Auto Backup Utility',
    subTitle: 'Solution to Backing up Databases',
    alterText: 'Console Application',
    delayAnimation: '200',
    portfolioLink: '',
  },
  {
    img: 'db-analysis',
    title: 'Analysis DB Utility',
    subTitle:
      'Solution to give alert for corrupated, missing or duplicate data into Databases',
    alterText: 'Console Application',
    delayAnimation: '0',
    portfolioLink: '',
  },
  {
    img: 'Ashwin-Hydrotech-Industries',
    title: 'Ashwin Hydrotech Industries',
    subTitle:
      'Website for marketing business products of Client over the internet',
    alterText: 'Static Responsive HTML Website',
    delayAnimation: '100',
    portfolioLink: 'http://www.ashwinhydrotech.com/',
  },
  {
    img: 'spbi',
    title: 'Shital Poly Block Industries',
    subTitle:
      'Website for marketing business products of Client over the internet',
    alterText: 'Static Responsive HTML Website',
    delayAnimation: '200',
    portfolioLink: 'http://www.spbi.in/',
  },
  {
    img: 'tejas-industries',
    title: 'Tejas Industries',
    subTitle:
      'Website for marketing business products of Client over the internet',
    alterText: 'Static Reponsive HTML Website',
    delayAnimation: '0',
    portfolioLink: 'http://www.hdpepipejointingmachine.com/',
  },
  {
    img: 'pso-01-home',
    title: 'Property Solutions Online',
    subTitle:
      'Solution to manage Sellers / Landlords, Buyers / Tenants, and Properties online',
    alterText: 'Web Application',
    delayAnimation: '100',
    portfolioLink: 'http://www.propertysolutionsonline.co.za/',
  },
  {
    img: 'alert-notification',
    title: 'Alert & Reminder Notifications',
    subTitle:
      'Solution to send alerts and scheduled reminders to the application users based on pre-defined settings for the users and reminders',
    alterText: 'Console Application',
    delayAnimation: '200',
    portfolioLink: '',
  },
  {
    img: 'sstm',
    title: 'SSTM (Secured System for Training Management)',
    subTitle:
      'Solution for managing the training course documents for their different training centers',
    alterText: 'Web Application',
    delayAnimation: '0',
    portfolioLink: '',
  },
  {
    img: 'text-to-html',
    title: 'TXT to HTML',
    subTitle: 'Solution for converting txt files into html',
    alterText: 'Console Application',
    delayAnimation: '100',
    portfolioLink: '',
  },
]

const WebAppPortfolioContent = [
  {
    img: 'harmonee',
    title: 'Harmonee',
    subTitle:
      'Simple app uses the power of many people to help local businesses and nonprofits build strong communities',
    alterText: 'Web Application',
    delayAnimation: '',
    portfolioLink: 'https://www.harmonee.org/',
  },
  {
    img: 'chat2cars',
    title: 'Chat2Cars | Buy and Sell Cars Online',
    subTitle: 'Solution for Online Car Marketplace',
    alterText: 'Web Application',
    delayAnimation: '',
    portfolioLink: 'https://chat2cars.co.za/',
  },
  {
    img: 'cps-home',
    title: 'CPS (Cloud Property Solutions)',
    subTitle: 'Solution for Estate and Managing Agencies',
    alterText: 'Web Application',
    delayAnimation: '100',
    portfolioLink: 'https://www.cloudpropertysolutions.co.za/',
  },
  {
    img: 'dia-Dashboard',
    title: 'Digital Internal Audit (Audit Management)',
    subTitle: 'Solution for accounting departments to manage Audits procedure',
    alterText: 'Web Application',
    delayAnimation: '200',
    portfolioLink: '',
  },
  {
    img: 'bcmtrac-05-home',
    title: 'Body Corporate / H.O.A. Management',
    subTitle:
      'Solution which can meet the requirements from single users to small, medium and large organizations',
    alterText: 'Web Application',
    delayAnimation: '0',
    portfolioLink: '',
  },
  {
    img: 'pso-01-home',
    title: 'Property Solutions Online',
    subTitle:
      'Solution to manage Sellers / Landlords, Buyers / Tenants, and Properties online',
    alterText: 'Web Application',
    delayAnimation: '100',
    portfolioLink: 'http://www.propertysolutionsonline.co.za/',
  },
  {
    img: 'sstm',
    title: 'SSTM (Secured System for Training Management)',
    subTitle:
      'Solution for managing the training course documents for their different training centers',
    alterText: 'Web Application',
    delayAnimation: '200',
    portfolioLink: '',
  },
]

const WebDesigningPortfolioContent = [
  {
    img: 'birdmorning',
    title: 'Bird Morning Solutions',
    subTitle: 'Wordpress Website for Company',
    alterText: 'Wordpress company website',
    delayAnimation: '',
    portfolioLink: 'https://www.birdmorning.com/',
  },
  {
    img: 'vedant',
    title: 'Vedant Groups Of Company',
    subTitle: 'Builder Scheme Website',
    alterText: 'Static Responsive HTML Website',
    delayAnimation: '',
    portfolioLink: 'http://www.vedantgroups.com/',
  },
  {
    img: 'Ashwin-Hydrotech-Industries',
    title: 'Ashwin Hydrotech Industries',
    subTitle:
      'Website for marketing business products of Client over the internet',
    alterText: 'Static Responsive HTML Website',
    delayAnimation: '100',
    portfolioLink: 'http://www.ashwinhydrotech.com/',
  },
  {
    img: 'spbi',
    title: 'Shital Poly Block Industries',
    subTitle:
      'Website for marketing business products of Client over the internet',
    alterText: 'Static Responsive HTML Website',
    delayAnimation: '200',
    portfolioLink: 'http://www.spbi.in/',
  },
  {
    img: 'tejas-industries',
    title: 'Tejas Industries',
    subTitle:
      'Website for marketing business products of Client over the internet',
    alterText: 'Static Reponsive HTML Website',
    delayAnimation: '0',
    portfolioLink: 'http://www.hdpepipejointingmachine.com/',
  },
]

const ConsolePortfolioContent = [
  {
    img: 'text-to-html',
    title: 'TXT to HTML',
    subTitle: 'Solution for converting txt files into html',
    alterText: 'Console Application',
    delayAnimation: '',
    portfolioLink: '',
  },
  {
    img: 'db-backup',
    title: 'Auto Backup Utility',
    subTitle: 'Solution to Backing up Databases',
    alterText: 'Console Application',
    delayAnimation: '100',
    portfolioLink: '',
  },
  {
    img: 'db-analysis',
    title: 'Analysis DB Utility',
    subTitle:
      'Solution to give alert for corrupated, missing or duplicate data into Databases',
    alterText: 'Console Application',
    delayAnimation: '200',
    portfolioLink: '',
  },
  {
    img: 'alert-notification',
    title: 'Alert & Reminder Notifications',
    subTitle:
      'Solution to send alerts and scheduled reminders to the application users based on pre-defined settings for the users and reminders',
    alterText: 'Console Application',
    delayAnimation: '0',
    portfolioLink: '',
  },
]

const PortfolioAnimation = () => {
  return (
    <SimpleReactLightbox>
      <div className="portfolio-filter-01">
        <Tabs>
          <TabList className="filter d-flex flex-wrap justify-content-start">
            {tabList.map((val, i) => (
              <Tab key={i}>{val}</Tab>
            ))}
          </TabList>
          {/* End tablist */}

          <SRLWrapper>
            <TabPanel>
              <div className="portfolio-content ">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {AllPortfolioContent.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a href={val.portfolioLink} target="_blank">
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a
                          href={`img/portfolio/${val.img}.jpg`}
                          className="gallery-link"
                        >
                          <ImgNextGen
                            srcWebp={`img/portfolio/${val.img}.webp`}
                            srcJrx={`img/portfolio/${val.img}.jxr`}
                            srcJp2={`img/portfolio/${val.img}.jp2`}
                            fallback={`img/portfolio/${val.img}.jpg`}
                            alt={val.alterText}
                          />
                        </a>
                        {/* End gallery link */}

                        <a
                          className="portfolio-icon"
                          href={val.portfolioLink}
                          target="_blank"
                        >
                          <FiLink />
                        </a>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  ))}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {WebAppPortfolioContent.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a href={val.portfolioLink} target="_blank">
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a
                          href={`img/portfolio/${val.img}.jpg`}
                          className="gallery-link"
                        >
                          <ImgNextGen
                            srcWebp={`img/portfolio/${val.img}.webp`}
                            srcJrx={`img/portfolio/${val.img}.jxr`}
                            srcJp2={`img/portfolio/${val.img}.jp2`}
                            fallback={`img/portfolio/${val.img}.jpg`}
                            alt={val.alterText}
                          />
                        </a>
                        {/* End gallery link */}

                        <a
                          className="portfolio-icon"
                          href={val.portfolioLink}
                          target="_blank"
                        >
                          <FiLink />
                        </a>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  ))}
                </Masonry>
                {/* grid item  */}
              </div>

              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {WebDesigningPortfolioContent.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a href={val.portfolioLink} target="_blank">
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a
                          href={`img/portfolio/${val.img}.jpg`}
                          className="gallery-link"
                        >
                          <ImgNextGen
                            srcWebp={`img/portfolio/${val.img}.webp`}
                            srcJrx={`img/portfolio/${val.img}.jxr`}
                            srcJp2={`img/portfolio/${val.img}.jp2`}
                            fallback={`img/portfolio/${val.img}.jpg`}
                            alt={val.alterText}
                          />
                        </a>
                        {/* End gallery link */}

                        <a
                          className="portfolio-icon"
                          href={val.portfolioLink}
                          target="_blank"
                        >
                          <FiLink />
                        </a>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  ))}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>

            <TabPanel>
              <div className="portfolio-content">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {ConsolePortfolioContent.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a href={val.portfolioLink} target="_blank">
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a
                          href={`img/portfolio/${val.img}.jpg`}
                          className="gallery-link"
                        >
                          <ImgNextGen
                            srcWebp={`img/portfolio/${val.img}.webp`}
                            srcJrx={`img/portfolio/${val.img}.jxr`}
                            srcJp2={`img/portfolio/${val.img}.jp2`}
                            fallback={`img/portfolio/${val.img}.jpg`}
                            alt={val.alterText}
                          />
                        </a>
                        {/* End gallery link */}

                        <a
                          className="portfolio-icon"
                          href={val.portfolioLink}
                          target="_blank"
                        >
                          <FiLink />
                        </a>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  ))}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>
          </SRLWrapper>
          {/* End tabpanel */}
        </Tabs>
      </div>
    </SimpleReactLightbox>
  )
}

export default PortfolioAnimation
